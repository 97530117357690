<template>
  <LoadingBar v-if="isLoading"></LoadingBar>
  <loading :active="isLoadingA" :is-full-page="true" :loader="'bars'" />
  <div class="flex flex-col h-full">
    <page-header>
      <page-title title="User Notifications"> </page-title>
    </page-header>

    <div class="vendor-container">
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        >
          <form>
            <div class="flex flex-col px-3 py-3 mt-5 bg-white sm:rounded-lg">
              <div class="flex items-center">
                <div class="flex-col flex-1">
                  <p class="flex-1 block w-full mt-1 ml-2 text-lg text-left">
                    SMS Messages
                  </p>
                </div>
                <div
                  class="
                    relative
                    inline-block
                    align-middle
                    transition
                    duration-200
                    ease-in
                    select-none
                    w-14
                  "
                >
                  <input
                    type="checkbox"
                    name="sms"
                    id="sms"
                    class="toggle-checkbox"
                    :value="inputs.sms_notification"
                    @change="notificationChange('sms_notification', $event)"
                    :checked="inputs.sms_notification"
                  />
                  <label
                    for="sms"
                    class="
                      block
                      h-8
                      overflow-hidden
                      bg-gray-300
                      rounded-full
                      cursor-pointer
                      toggle-label
                    "
                  ></label>
                </div>
              </div>
            </div>
            <div class="px-3 mt-1">
              <h3 class="flex-1 w-full ml-2 text-left text-gray-600">
                Enables delivery of reminder notifications via SMS. Ensure you
                have funds available in your SMS Top Up account.
              </h3>
            </div>
            <div class="flex flex-col px-3 py-3 mt-5 bg-white sm:rounded-lg">
              <div class="flex items-center">
                <div class="flex-col flex-1">
                  <p class="flex-1 block w-full mt-1 ml-2 text-lg text-left">
                    Prioritise Push Notifications
                  </p>
                </div>
                <div
                  class="
                    relative
                    inline-block
                    align-middle
                    transition
                    duration-200
                    ease-in
                    select-none
                    w-14
                  "
                >
                  <input
                    type="checkbox"
                    name="push"
                    id="push"
                    class="toggle-checkbox"
                    :value="inputs.push_notification"
                    @change="notificationChange('push_notification', $event)"
                    :checked="inputs.push_notification"
                  />
                  <label
                    for="push"
                    class="
                      block
                      h-8
                      overflow-hidden
                      bg-gray-300
                      rounded-full
                      cursor-pointer
                      toggle-label
                    "
                  ></label>
                </div>
              </div>
            </div>
            <div class="px-3 mt-1">
              <h3 class="flex-1 w-full ml-2 text-left text-gray-600">
                Prioritise the delivery of push notifications over SMS messages.
                Reminders may still be sent via SMS if enabled.
              </h3>
            </div>
            <div class="flex flex-col px-3 py-3 mt-5 bg-white sm:rounded-lg">
              <div class="flex items-center">
                <div class="flex-col flex-1">
                  <p class="flex-1 block w-full mt-1 ml-2 text-lg text-left">
                    Email Notifications
                  </p>
                </div>
                <div
                  class="
                    relative
                    inline-block
                    align-middle
                    transition
                    duration-200
                    ease-in
                    select-none
                    w-14
                  "
                >
                  <input
                    type="checkbox"
                    name="email"
                    id="email"
                    class="toggle-checkbox"
                    :value="inputs.email_notification"
                    @change="notificationChange('email_notification', $event)"
                    :checked="inputs.email_notification"
                  />
                  <label
                    for="email"
                    class="
                      block
                      h-8
                      overflow-hidden
                      bg-gray-300
                      rounded-full
                      cursor-pointer
                      toggle-label
                    "
                  ></label>
                </div>
              </div>
            </div>
            <div class="px-3 mt-1">
              <h3 class="flex-1 w-full ml-2 text-left text-gray-600">
                Send order notifications via email.
              </h3>
            </div>
          </form>
          <div class="px-3 mt-6">
            <h3 class="flex-1 w-full ml-2 text-left text-gray-600">
              REMINDER PROMPT
            </h3>
          </div>
          <div class="flex flex-col px-3 py-3 mt-1 bg-white sm:rounded-lg">
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <p class="flex-1 block w-full mt-1 ml-2 text-left text-md">
                  {{ reminderText }}
                </p>
              </div>
              <button class="flex m-2 mr-2" @click="openReminderPrompt">
                <p class="px-2 ml-2 text-qgo-primary-bg">Frequency Settings</p>
                <span class="px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="20"
                    viewBox="0 0 50 50"
                  >
                    <g
                      id="Group_286"
                      data-name="Group 286"
                      transform="translate(-1481 -808)"
                    >
                      <g
                        id="Ellipse_19"
                        data-name="Ellipse 19"
                        transform="translate(1481 808)"
                        fill="none"
                        stroke="#009A74"
                        stroke-width="2"
                      >
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="24" fill="none" />
                      </g>
                      <g
                        id="Group_238"
                        data-name="Group 238"
                        transform="translate(413 100)"
                      >
                        <path
                          id="Path_216"
                          data-name="Path 216"
                          d="M4591.953,932.809h21.555"
                          transform="translate(-3510.181 -200.086)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        />
                        <path
                          id="Path_217"
                          data-name="Path 217"
                          d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                          transform="translate(-3543.586 -161)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal for reminder prompt -->
    <div v-if="reminderPromptModal">
      <modal-view>
        <ReminderPrompt :notificationValue="inputs" :reminderStrings="remindersArray" @frequencyUpdated="frequencyUpdated"></ReminderPrompt>
      </modal-view>
    </div>

    <page-footer>
      <footer-nav></footer-nav>
    </page-footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import ModalView from "@/components/Modal.vue";
import ReminderPrompt from "@/views/Vendor/Modal/ReminderPrompt.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    ModalView,
    ReminderPrompt,
    Loading,
  },
  data() {
    return {
      inputs: {
        sms_notification: 0,
        push_notification: 0,
        email_notification: 0,
        reminder_prompt: 0
      },
      isLoadingA: false,
      reminderShowText: '',
      remindersArray:[]
    };
  },
  methods: {
    frequencyUpdated(value){
      this.inputs.reminder_prompt = value;
    },
    updateNotifications() {
      this.isLoadingA = true;
      this.$http
        .post("console/update/notification", this.inputs)
        .then((response) => {
          this.isLoadingA = false;
          if (response && response.data && response.data.status) {
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },

    notificationChange(type, e) {
      let value = e.target.checked;
      switch (type) {
        case "sms_notification":
          if (value) {
            this.inputs.sms_notification = 1;
          } else {
            this.inputs.sms_notification = 0;
          }
          break;
        case "push_notification":
          if (value) {
            this.inputs.push_notification = 1;
          } else {
            this.inputs.push_notification = 0;
          }
          break;
        case "email_notification":
          if (value) {
            this.inputs.email_notification = 1;
          } else {
            this.inputs.email_notification = 0;
          }
          break;
        default:
          break;
      }
      this.updateNotifications();
    },
    getNotificationsValue() {
      this.isLoadingA = true;
      this.$http
        .get("console/get-notification")
        .then((response) => {
          this.isLoadingA = false;
          if (response && response.data && response.data.status) {
            this.inputs.sms_notification =
              response.data.screen.sms_notification;
            this.inputs.push_notification =
              response.data.screen.push_notification;
            this.inputs.email_notification =
              response.data.screen.email_notification;
              this.inputs.reminder_prompt =
              response.data.screen.reminder_prompt;
            this.remindersArray = response.data.reminder;
            let reminderData = response.data.reminder.find(e => e.id == response.data.screen.reminder_prompt);
            console.log(reminderData);
            if(reminderData) {
              this.$store.commit("notifications/setReminderText", reminderData.name);
            }
            // let repeatFrequ = 0;
            // switch (response.data.screen.reminder_prompt) {
            //   case 1:
            //     repeatFrequ = 2;
            //     break;
            //   case 2:
            //     repeatFrequ = 5;
            //     break;
            //   case 3:
            //     repeatFrequ = 10;
            //     break;
            //   default:
            //     break;
            // }
            // if (repeatFrequ) {
            //   this.$store.commit(
            //     "notifications/setReminderText",
            //     `Remind once ready, then repeat ${repeatFrequ} times in ${repeatFrequ} minutes`
            //   );
            // } else {
            //   this.$store.commit("notifications/setReminderText", "Remind once");
            // }
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    setNotificationData(val, e) {
      let name =
        val === "smsMessage"
          ? "sms_messages"
          : val === "pushNotification"
          ? "push_notifications"
          : val === "emailNotifications"
          ? "email_notifications"
          : "n/a";
      let payload = {
        key: "notification",
        name: name,
        value: e.target.checked,
      };
      this.$store.commit("notifications/setInputData", payload);
      this.$store
        .dispatch("notifications/updateNotificationData", payload)
        .then(
          (response) => {
            console.log(response);
            this.$swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "success",
              title: response.value + " " + response.name,
            });
          },
          (error) => {
            console.log(error);
            this.$swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "error",
              title: "Update Failed",
            });
          }
        );
    },
    openReminderPrompt() {
      this.$store.commit("notifications/setReminderPromptModal", true);
    },
  },
  computed: {
    notificationData() {
      console.log(this.notificationData);
      return this.$store.getters["notifications/inputData"];
    },
    isLoading() {
      return this.$store.getters["notifications/isLoading"];
    },
    reminderPromptModal() {
      return this.$store.getters["notifications/reminderPromptModal"];
    },
    reminderText() {
      return this.$store.getters["notifications/reminderText"];
    },
  },
  created() {
    // this.$store.dispatch("notifications/fetchNotificationInitialData").then(
    //   (response) => {
    //     console.log(response);
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text: "Something went wrong while fetching settings for Notifications!",
    //       timer: 5000,
    //     });
    //   }
    // );
    this.getNotificationsValue();
  },
});
</script>
