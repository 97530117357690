<template>
  <div class="flex-col items-center w-full px-5 py-5 bg-white rounded-2xl">
    <loading :active="isLoadingA" :is-full-page="true" :loader="'bars'" />
    <div>
      <span class="text-lg font-bold">Frequency Settings</span>
    </div>
    <div class="mb-5">
      <span class="pt-1 text-md">
        For how many times would you like to notify customers</span
      >
    </div>
    <div class="mt-3" v-for="reminder in reminderStrings" v-bind:key="reminder.id" >
      <hr />
      <a
        href="#"
        @click="updateNotification(reminder.id)"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        {{ reminder.name }}
      </a>
    </div>
    <!-- <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="updateNotification(0)"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Remind once
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="updateNotification(1)"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Remind 2 times in 2 minutes
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="updateNotification(2)"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Remind 5 times in 5 minutes
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="updateNotification(3)"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Remind 10 times in 10 minutes
      </a>
    </div> -->
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="cancelReminderPrompt"
        class="pt-1 text-red-600 text-md"
      >
        Cancel
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  props: {
    notificationValue: Object,
    reminderStrings: Array
  },
  methods: {
    updateNotification(value) {
       this.isLoadingA = true;
      this.notificationValue.reminder_prompt = parseInt(value);
      this.$http
        .post("console/update/notification", this.notificationValue)
        .then((response) => {
          this.cancelReminderPrompt();
          this.isLoadingA = false;
          if (response && response.data && response.data.status) {
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });

            let reminderData = this.reminderStrings.find(e => e.id == value);
            console.log(reminderData);
            if(reminderData) {
              this.$store.commit("notifications/setReminderText", reminderData.name);
              sessionStorage.setItem('notificationFrequency', reminderData.value);
            }
            
            // let repeatFrequ = 0;
            // switch (value) {
            //   case 1:
            //     repeatFrequ = 2;
            //     break;
            //   case 2:
            //     repeatFrequ = 5;
            //     break;
            //   case 3:
            //     repeatFrequ = 10;
            //     break;
            //   default:
            //     break;
            // }
            // sessionStorage.setItem('notificationFrequency', repeatFrequ);
            // if (repeatFrequ) {
            //   this.$store.commit(
            //     "notifications/setReminderText",
            //     `Remind once ready, then repeat ${repeatFrequ} times in ${repeatFrequ} minutes`
            //   );
            // } else {
            //   this.$store.commit("notifications/setReminderText", "Remind once");
            // }
            this.$emit('frequencyUpdated', value)
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    exportStats(repeat, time) {
      this.$store.commit("notifications/setLoading", true);
      console.log(repeat, time);
      if (repeat === 1 && time === 0) {
        this.$store.commit("notifications/setReminderText", "Remind once");
      } else {
        this.$store.commit(
          "notifications/setReminderText",
          `Remind once ready, then repeat ${repeat} times in ${time} minutes`
        );
      }
      //   let body = {
      //     repeat: repeat,
      //     time: time
      //   };
      //   axios
      //     .post("vendor/csv/6", body)
      //     .then(response => {
      //       console.log(response.data);

      //       this.$store.commit("notifications/setLoading", false);
      //     })
      //     .catch(error => {
      //       let errorMessage = error.message;
      //       console.error("There was an error!", error);
      //       console.log(errorMessage);
      //       this.$swal.fire({
      //         icon: "error",
      //         text: "Something went wrong while fetching statistics!",
      //         timer: 5000
      //       });
      //       this.$store.commit("notifications/setLoading", false);
      //     });

      this.$store.commit("notifications/setLoading", false);
      this.$store.commit("notifications/setReminderPromptModal", false);
    },
    cancelReminderPrompt() {
      this.$store.commit("notifications/setReminderPromptModal", false);
    }
  },
  computed: {
    reminderPromptModal() {
      return this.$store.getters["notifications/reminderPromptModal"];
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      isLoadingA: false,
    }
  }
});
</script>
